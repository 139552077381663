export default [
  {
    key: 'name',
    label: 'field.supplierName',
    rules: 'required|max:100',
    type: 'text',
    cols : 6
  },
  {
    key: 'email',
    label: 'field.email',
    rules: 'required|max:100',
    type: 'text',
    cols : 6
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required|max:50',
    type: 'tel',
    cols : 6
  },
  {
    key: 'leadTime',
    label: 'field.leadTime',
    rules: 'required|integer|max_value:100',
    type: "currency",
    suffix: "days",
    cols : 6,
  },
  {
    key: 'address',
    label: 'field.address',
    rules: 'required|max:300',
    type: 'textarea',
    cols : 12
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
  